import React from 'react';
import { Select, FormControl, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { AssetListElement, AssetListElementSettings, UpdateAssetDetails } from '../../types';

interface AssetDropDownProps {
    value: string;
    options?: AssetListElement[] | UpdateAssetDetails[];
    optionsSettings?: AssetListElementSettings[];
    handleChange: (event: SelectChangeEvent) => void;
}

const AssetDropDown: React.FC<AssetDropDownProps> = ({ value, options, optionsSettings, handleChange }) => {
    const itemsToDisplay = options || optionsSettings;

    return (
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
            <InputLabel id="asset-name-label">Asset Name</InputLabel>
            <Select
                labelId="asset-name-label"
                id="select-asset-name"
                value={value}
                label="Asset Name"
                onChange={handleChange}
            >
                {itemsToDisplay?.map((item, index) => (
                    <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default AssetDropDown;
