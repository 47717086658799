
type SeriesSize = 'sm' | 'md' | 'lg';

export interface AssetListElement {
  name: string;
  ID: string;
  deviceID: string;
  parts: Part[];
}

export interface PaymentResponse {
  token: string;
  action: string;
  amount: number;
  unit_price: number;
  devices: string[];
}

export interface AssetSettings {
  anchorTolerance : number
  tirePressureSet : number
  tirePressureHighDelta : number
  tirePressureLowDelta : number
  tireTemperatureAmbient : number
  tireTemperatureHighDelta : number
}

export interface AssetListElementSettings {
  name: string;
  id:string;
  settings: AssetSettings;
}

export interface getAssetsResponse {
  data : {
      assets: {
          items: AssetListElement[];
      }
  };
}


export interface Part {
  name: string;
  id: string;
}


export interface CreateAssetResponse {
  data: {
    createAsset: {
      ok: boolean;
      asset: AssetCreate | null;
      errors: string[] | null;
    };
  };
}

export interface CreateAssetPartResponse {
  data: {
    createAssetPart : {
      ok: boolean;
      errors: string[] | null;
    }
  };
};

export interface UpdateAssetResponse {
  data: {
    updateAsset: {
      ok: boolean;
      errors: string[] | null;
    };
  };
}

export interface GetDeviceListResponse {
  data: {
    assets: {
      items: Flexcase[];
    }
  };
  errors: string[] | null;
}

export interface DeleteAssetPartResponse {
  data: {
    deleteAssetPart: {
      ok: boolean;
      errors: string[] | null;
    };
  };
  errors: string[] | null;
}

interface AssetCreate {
  id: string;
  name: string;
}

export interface SimpleOptions {
  text: string;
  showSeriesCount: boolean;
  seriesCountSize: SeriesSize;
}

export interface MySecureJsonData {
  apiKey?: string;
}

export interface MyDataSourceOptions {
  apiUrl?: string;
}

export interface Flexcase {
  flexcase: {
    deviceId: string;
    subscribed: boolean;
  };
}

export interface Asset {
  id: string;
  category: string;
  flexcase: Flexcase;
}

export interface AssetsData {
  assets: {
    items: Asset[];
    nextCursor?: string;
  };
}

export interface AssetsVars {
  pageSize?: number;
  org: string;
}

export interface DeleteAssetPartData {
  deleteAssetPart: {
    ok: boolean;
    errors?: string[];
  };
}

export interface DeleteAssetPartVars {
  assetId: string;
  partId: string;
}

export interface CreateAssetPartData {
  createAssetPart: {
    id: string;
    name: string;
  };
}

export interface CreateAssetPartVars {
  assetId: string;
  name: string;
}

export interface Battery {
  cycleNumber: number;
}

export interface Axles {
  axleCount: AxleCount;
  wheelConfiguration: WheelConfiguration;
}

export enum AxleCount {
  Default = "",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
}

export enum WheelConfiguration {
  Default = "",
  ONE_TWO = "ONE_TWO",
  ONE_FOUR = "ONE_FOUR",
  TWO_TWO_BY_TWO = "TWO_TWO_BY_TWO",
  TWO_FOUR_BY_FOUR = "TWO_FOUR_BY_FOUR",
  THREE_TWO_TWO_TWO = "THREE_TWO_TWO_TWO",
  THREE_FOUR_FOUR_FOUR = "THREE_FOUR_FOUR_FOUR",
  THREE_TWO_TWO_FOUR = "THREE_TWO_TWO_FOUR",
  THREE_TWO_FOUR_TWO = "THREE_TWO_FOUR_TWO",
  THREE_TWO_FOUR_FOUR = "THREE_TWO_FOUR_FOUR"
}

export interface Odometer {
  mandatoryServiceDistance: number;
  serviceDate: string;
}

export interface AssetInput {
  name: string;
  VIN: string;
  year: number;
  model: string;
  category: string;
  color: string;
  flexcase: string;
  settings?: string;
  battery: Battery;
  axles: Axles;
  odometer: Odometer;
}

export interface AssetUpdateInput {
  name?: string;
  VIN?: string;
  year?: number;
  model?: string;
  category?: string;
  color?: string;
  flexcase?: string;
  settings?: string;
  battery?: Battery;
  axles?: Axles;
  odometer?: Odometer;
}

export const defaultInput: AssetInput = {
  name: '',
  VIN: '',
  year: 0,
  model: '',
  category: '',
  color: '',
  flexcase: '',
  battery: {
    cycleNumber: 0,
  },
  axles: {
    axleCount:AxleCount.Default,
    wheelConfiguration: WheelConfiguration.Default,
  },
  odometer: {
    mandatoryServiceDistance: 0,
    serviceDate: '',
  },
};

export const defaultUpdateInput: AssetUpdateInput = {
  name: '',
  VIN: '',
  year: 0,
  model: '',
  category: '',
  color: '',
  flexcase: '',
  battery: {
    cycleNumber: 0,
  },
  axles: {
    axleCount:AxleCount.Default,
    wheelConfiguration: WheelConfiguration.Default,
  },
  odometer: {
    mandatoryServiceDistance: 0,
    serviceDate: '',
  },
};

export type OrgInfoResponse = { 
  data: {
      user: {
          permissions: Array<{
              org: string;
              permissions: {
              admin: boolean;}
          }>;
      };
  };
  errors: null | Array<{
      message: string; 
      locations?: Array<{ line: number; column: number }>; 
      path?: string[]; 
  }>; 
};

export type OrganizationInfo = {
  id: string;                      
  name: string | null;            
  users: { id: string; username: string }[]; 
};

export interface User {
  id: string;       // User ID
  username: string; // Username
}

export interface UpdateAssetDetails {
  id: string;
  name: string;
  VIN: string;
  year: number;
  model: string;
  category: string;
  color: string;
  flexcase: {
    deviceId: string;
  };
  trailerBattery: Battery; 
  axles: Axles; 
  odometer: Odometer;
}


export interface getAssetsDetailsResponse {
  data: {
    assets: {
      items: UpdateAssetDetails[];
    };
  };
}

export interface AssetSettingsResponse {
  data: {
    updateAssetSettings: {
      ok: boolean;
      asset: {
        id: string;
        name: string;
        settings: {
          anchorTolerance: number;
          tirePressureSet: number;
          tirePressureHighDelta: number;
          tirePressureLowDelta: number;
          tireTemperatureAmbient: number;
          tireTemperatureHighDelta: number;
        };
      };
      errors: string[] | null;
      updatedSettingFields: string[];
    };
  };
  errors: string[] | null;
}
