import React from 'react';
import { TextField } from '@mui/material';


interface BatteryDetailsProps {
    cycleNumber: number;
    required?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled? : boolean;
}

const BatteryDetails: React.FC<BatteryDetailsProps> = ({ cycleNumber, required, handleChange,disabled }) => {
    return (
        <div>
        <h3>Battery</h3>
        <TextField
          label="Cycle Number"
          value={cycleNumber}
          onChange={handleChange}
          type="number"
          fullWidth
          required={required}
          style={{ marginBottom: '10px' }}
          disabled={disabled}
        />
      </div>
    );
}

export default BatteryDetails;
