import React from 'react';
import {Tabs, Tab, Box} from '@mui/material';
import CreateAsset from './CreateAssetForm';
import UpdateAsset from './UpdateAssetForm';
import AssetSettings from './AssetSettings'; 

const Asset: React.FC = () => {
  const [value, setValue] = React.useState('create');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue); 
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="asset tabs"
      >
        <Tab value="create" label="Create Asset" />
        <Tab value="update" label="Update Asset" />
        <Tab value="settings" label="Asset Settings" />
      </Tabs>

      {value === 'create' && <CreateAsset />}
      {value === 'update' && <UpdateAsset />}
      {value === 'settings' && <AssetSettings />}
    </Box>
  );
};

export default Asset;

