import React, { useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import OdometryInput from '../Odometry/OdometryForm';
import AxleDetails from '../Axle/AxleDetailsForm';
import BatteryDetails from '../Battery/BatteryDetails';
import AssetDetails from './AssetDetails';
import AssetDropDown from './AssetsDropdown';
import { Alert, Button, FormControl, TextField } from '@mui/material';
import DataService from 'services/dataService';
import {AssetUpdateInput, AxleCount, UpdateAssetDetails, WheelConfiguration } from '../../types';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const UpdateAsset: React.FC = () => {
  const [assetName, setAssetName] = React.useState('');
  const [assetId, setAssetId] = React.useState('');
  const [assetsList, setAssetsList] = React.useState<UpdateAssetDetails[]>([]);
  const [selectedAsset, setSelectedAsset] = React.useState<UpdateAssetDetails | null>(null);
  const [initialFormValues, setInitialFormValues] = React.useState<UpdateAssetDetails>();
  const [flexcase, setFlexcase] = React.useState('');
  const [axleCount, setAxleCount] = React.useState(AxleCount.Default);
  const [wheelConfiguration, setWheelConfiguration] = React.useState(WheelConfiguration.Default);
  const [VIN, setVIN] = React.useState('');
  const [model, setModel] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [color, setColor] = React.useState('');
  const [cycleNumber, setCycleNumber] = React.useState(0);
  const [serviceDistance, setServiceDistance] = React.useState(0);
  const [year, setYear] = React.useState(0);
  const [serviceDate, setServiceDate] = React.useState('');

  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [severity, setSeverity] = React.useState<'success' | 'error' | null>(null);

  useEffect(() => {
    const fetchAssets = async () => {
      const datasource = new DataService();
      const assets = await datasource.getAssetsDetails();
      setAssetsList(assets);
    };

    fetchAssets();
  }, []);

  const handleChangeAssetName = (event: SelectChangeEvent) => {
    const selectedName = event.target.value as string;
    setAssetName(selectedName);

    const selectedAsset = assetsList.find((asset) => asset.name === selectedName);
    if (selectedAsset) {
      setSelectedAsset(selectedAsset);
      setFlexcase(selectedAsset.flexcase.deviceId);
      setAssetId(selectedAsset.id);
    } else {
      setSelectedAsset(null);
    }
  };

  useEffect(() => {
    if (selectedAsset) {
      setInitialFormValues({
        flexcase: selectedAsset.flexcase,
        VIN: selectedAsset.VIN,
        model: selectedAsset.model,
        category: selectedAsset.category,
        color: selectedAsset.color,
        trailerBattery: selectedAsset.trailerBattery,
        axles: selectedAsset.axles,
        odometer: selectedAsset.odometer,
        year: selectedAsset.year || 0,
        id: selectedAsset.id,
        name: selectedAsset.name,
      });

      setFlexcase(selectedAsset.flexcase.deviceId);
      setVIN(selectedAsset.VIN || '');
      setModel(selectedAsset.model || '');
      setCategory(selectedAsset.category || '');
      setColor(selectedAsset.color || '');
      setCycleNumber(selectedAsset.trailerBattery?.cycleNumber || 0);
      setAxleCount(selectedAsset.axles?.axleCount || AxleCount.Default);
      setWheelConfiguration(selectedAsset.axles?.wheelConfiguration || WheelConfiguration.Default);
      setServiceDistance(selectedAsset.odometer?.mandatoryServiceDistance || 0);
      const serviceDateString = selectedAsset.odometer?.serviceDate;
      setServiceDate(serviceDateString ? serviceDateString.split('T')[0] : '');
      setYear(selectedAsset.year || 0);
    }
  }, [selectedAsset]);

  const handleUpdate = async () => {
    const updatePayload: AssetUpdateInput = {};

    if (initialFormValues) {
      if (assetName && assetName !== selectedAsset?.name) updatePayload.name = assetName;
      if (flexcase !== initialFormValues.flexcase.deviceId) updatePayload.flexcase = flexcase;
      if (VIN !== initialFormValues.VIN) updatePayload.VIN = VIN;
      if (model !== initialFormValues.model) updatePayload.model = model;
      if (category !== initialFormValues.category) updatePayload.category = category;
      if (color !== initialFormValues.color) updatePayload.color = color;
      if (cycleNumber !== initialFormValues.trailerBattery.cycleNumber) {
        updatePayload.battery = { cycleNumber };
      }
      if (axleCount !== initialFormValues.axles.axleCount || wheelConfiguration !== initialFormValues.axles.wheelConfiguration) {
        updatePayload.axles = { axleCount, wheelConfiguration };
      }
      if (serviceDistance !== initialFormValues.odometer.mandatoryServiceDistance || serviceDate !== initialFormValues.odometer.serviceDate) {
        updatePayload.odometer = { mandatoryServiceDistance: serviceDistance, serviceDate };
      }
      if (year !== initialFormValues.year) updatePayload.year = year;
    }

    const datasource = new DataService();
    const result = await datasource.updateAsset(assetId, updatePayload);

    if (result.success) {
      setAlertMessage('Asset updated successfully!');
      setSeverity('success');
    } else {
      setAlertMessage(`Error updating asset: ${result.error?.join(', ')}`);
      setSeverity('error');
    }
  };

  const isFormDisabled = selectedAsset === null;

  return (
    <FormControl fullWidth>
      <h1>Update Asset</h1>

      {assetsList.length === 0 ? (
        <Alert severity="warning">No assets available to choose from</Alert>
      ) : (
        <AssetDropDown value={assetName} options={assetsList} handleChange={handleChangeAssetName} />
      )}
      
      <TextField
        label="Asset Name"
        value={assetName}
        onChange={(e) => setAssetName(e.target.value)}
        fullWidth
        margin="normal"
        disabled={isFormDisabled}
      />
      
      <AssetDetails
        VIN={VIN}
        model={model}
        category={category}
        color={color}
        flexcase={flexcase}
        year={year}
        handleChange={(field, value) => {
          if (field === 'VIN') setVIN(value);
          else if (field === 'model') setModel(value);
          else if (field === 'category') setCategory(value);
          else if (field === 'color') setColor(value);
          else if (field === 'flexcase') setFlexcase(value);
          else if (field === 'year') setYear(Number(value));
        }}
        disabled={isFormDisabled}
      />
      
      <BatteryDetails 
        cycleNumber={cycleNumber}
        handleChange={(e) => setCycleNumber(Number(e.target.value))}
        disabled={isFormDisabled}
      />

      <AxleDetails 
        axleCount={axleCount} 
        wheelConfiguration={wheelConfiguration} 
        handleAxleChange={(e) => setAxleCount(e.target.value as AxleCount)} 
        handleWheelChange={(e) => setWheelConfiguration(e.target.value as WheelConfiguration)} 
        disabled={isFormDisabled}
      />

      <OdometryInput 
        serviceDistance={serviceDistance}
        serviceDate={serviceDate}
        handleChange={(e) => setServiceDistance(Number(e.target.value))}
        handleDateChange={(e) => setServiceDate(e.target.value)}
        disabled={isFormDisabled}
      />

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleUpdate} 
        disabled={isFormDisabled}
      >
        Update Asset
      </Button>

      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </FormControl>
  );
};

export default UpdateAsset;
