import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Alert,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DataService from 'services/dataService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const CreateUser: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [skipConfirmation, setSkipConfirmation] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<'success' | 'error' | null>(null);

  const handleCreate = async () => {
    try {
      const dataService = new DataService();
      const userInfo = {
        username: username,
        password: password,
        email: email,
    };
      const result = await dataService.createUser(userInfo, skipConfirmation);

      if (result.success) {
        setAlertMessage('User created successfully!');
        setSeverity('success');
      } else {
        setAlertMessage(`Error creating user: ${result.error}`);
        setSeverity('error');
      }
    } catch (error) {
      setAlertMessage('Error creating user: ' + (error instanceof Error ? error.message : 'Unknown error'));
      setSeverity('error');
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleCreate();
  };

  return (
    <Box mb={2}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={skipConfirmation}
                  onChange={(e) => setSkipConfirmation(e.target.checked)}
                />
              }
              label="Skip Confirmation"
            />
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Submit
        </Button>
      </form>

      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
};

export default CreateUser;
