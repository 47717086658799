import React from 'react';
import {Tabs, Tab, Box} from '@mui/material';
import CreateAssetPart from './CreatePart';
import DeleteAssetPart from './DeletePart';


const Asset: React.FC = () => {
  const [value, setValue] = React.useState('create');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue); 
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="asset part tabs"
      >
        <Tab value="create" label="Create Asset Part" />
        <Tab value="delete" label="Delete Asset Part" />
      </Tabs>

      {value === 'create' && <CreateAssetPart />}
      {value === 'delete' && <DeleteAssetPart />}
    </Box>
  );
};

export default Asset;

