import React, { useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import AssetDropDown from '../Assets/AssetsDropdown';
import { Alert, Button, FormControl, TextField } from '@mui/material';
import DataService from 'services/dataService';
import { AssetListElement } from '../../types';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const CreateAssetPart: React.FC = () => {
  const [assetName, setAssetName] = React.useState('');
  const [assetId, setAssetId] = React.useState('');
  const [partName, setPartName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [manufacturedDate, setManufacturedDate] = React.useState('');

  const [assetsList, setAssetsList] = React.useState<AssetListElement[]>([]);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [severity, setSeverity] = React.useState<'success' | 'error' | null>(null);

  const handleChangeAssetName = (event: SelectChangeEvent) => {
    const selectedName = event.target.value as string;
    setAssetName(selectedName);

    const selectedAsset = assetsList.find((asset) => asset.name === selectedName);
    if (selectedAsset) {
      setAssetId(selectedAsset.ID);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datasource = new DataService();
        const assets = await datasource.getAssets();
        setAssetsList(assets);
      } catch (error) {
        setAlertMessage('Error fetching assets: ' + error);
        setSeverity('error');
      }
    };

    fetchData();
  }, []);

  const handleCreatePart = async () => {
    const dataService = new DataService();

    const payload: any = {
      assetId: assetId,           
      name: partName                
    };

    if (description) {
      payload.description = description; 
    }
    if (number) {
      payload.number = number;           
    }
    if (manufacturedDate) {
      payload.manufactured_date = manufacturedDate; 
    }

    const result = await dataService.createPart(payload);

    if (result.success) {
      setAlertMessage('Asset part created successfully!');
      setSeverity('success');
      setPartName('');
      setDescription('');
      setNumber('');
      setManufacturedDate('');
    } else {
      setAlertMessage(`Error creating asset part: ${result.error?.join(', ')}`);
      setSeverity('error');
    }
  };

  const isCreateButtonDisabled = !(assetId && partName);

  return (
    <FormControl fullWidth>
      <br />
      <h1>Create Asset Part</h1>
      <br />

      {assetsList.length === 0 ? (
        <Alert severity="warning">No assets available</Alert>
      ) : (
        <AssetDropDown value={assetName} options={assetsList} handleChange={handleChangeAssetName} />
      )}

      {assetName !== '' && (
        <>
          <TextField
            label="Part Name"
            value={partName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPartName(e.target.value)}
            style={{ marginBottom: '10px' }}
            required
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Number"
            value={number}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNumber(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Manufactured Date"
            type="date" // Date picker format
            value={manufacturedDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setManufacturedDate(e.target.value)}
            style={{ marginBottom: '10px' }}
            InputLabelProps={{ shrink: true }} // Keeps label shrunk when date is selected
          />
          <Button variant="contained" color="primary" onClick={handleCreatePart} disabled={isCreateButtonDisabled}>
            Create Asset Part
          </Button>
        </>
      )}
      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </FormControl>
  );
};

export default CreateAssetPart;
