import { getDataSourceSrv } from '@grafana/runtime';
import { AssetInput, AssetListElementSettings, AssetSettingsResponse, AssetUpdateInput,CreateAssetPartResponse,CreateAssetResponse, DeleteAssetPartResponse, getAssetsDetailsResponse, GetDeviceListResponse, PaymentResponse, UpdateAssetDetails, UpdateAssetResponse } from 'types';
import { getAssetsResponse } from 'types';



class DataService {

    private datasource: any = null;
    private dataSourceName: string = 'audesse-grafanabackend-datasource';

    constructor(){
        this.initializeDataSource();
    }

    private async ensureDataSourceInitialized() {
        if (!this.datasource) await this.initializeDataSource();
    }

    async initializeDataSource(){
        if (!this.datasource) {
            const dataSourceSrv: any = getDataSourceSrv();
            Object.keys(dataSourceSrv.datasources).forEach((key: string) => {
                if (dataSourceSrv.datasources[key].meta.id === this.dataSourceName) {
                  this.datasource = dataSourceSrv.datasources[key];
                }
            });
        } 
        if (!this.datasource) throw new Error('Datasource not found');
        return this.datasource;
    }

    async createAsset(asset: AssetInput){
        await this.ensureDataSourceInitialized();
        const result : CreateAssetResponse = await this.datasource.postResource('create-asset', { asset: asset });
        const createAssetData = result.data.createAsset;
        if (createAssetData.ok) {
            return {success:true, error: null};
        }
        return {success:false, error: createAssetData.errors};
    }

    async getAssets(){
        await this.ensureDataSourceInitialized();
        const result : getAssetsResponse = await this.datasource.postResource('get-assets');
        const assets = result.data.assets.items.map((item: any) => ({
            name: item.name,
            deviceID: item.flexcase?.deviceId,
            ID: item.id,
            parts: item.parts || []
        }));
        return assets;
    }

    async getAssetsDetails():Promise<UpdateAssetDetails[]> {
        await this.ensureDataSourceInitialized();
        const result: getAssetsDetailsResponse = await this.datasource.postResource('get-assets-details');
        const assets = result.data.assets.items.map((item: UpdateAssetDetails) => ({
            id: item.id,
            name: item.name,
            VIN: item.VIN,
            year: item.year,
            model: item.model,
            category: item.category,
            color: item.color,
            flexcase: item.flexcase,
            trailerBattery: item.trailerBattery,
            axles: item.axles,
            odometer: item.odometer,
        }));
        return assets;
    }
    
    async get_orgs_users_info() {
        await this.ensureDataSourceInitialized();
        const result = await this.datasource.postResource('get-admin-users-in-orgs');
        return result;
    }

    async addUserToOrg(userId: string, orgId: string, permissions: { [key: string]: boolean }) {
        await this.ensureDataSourceInitialized();
        const payload = {
            userId:  userId ,
            orgId:  orgId ,
            permissions: permissions,
        };
        const result: any = await this.datasource.postResource('addUserToOrg', payload);
        if (result.data && result.data.addUserToOrg && result.data.addUserToOrg.ok) {
            return { success: true, error: null };
        }
        return { success: false, error: result.errors };
    }

    async updateUser(updateData: { [key: string]: any }) {
        await this.ensureDataSourceInitialized();
    
        const result: any = await this.datasource.postResource('updateUserInfo', {userInfo: updateData});
        if (result.data && result.data.updateUser && result.data.updateUser.ok) {
            return { success: true, error: null };
        }
        return { success: false, error: result.data.updateUser.message };
    }

    async removeUser(userId: string, orgId: string) {
        await this.ensureDataSourceInitialized();
    
        // Prepare the request payload
        const payload = {
          userId: userId,
          orgId: orgId,
        };
    
        const result: any = await this.datasource.postResource('removeUserFromOrg', payload);
    
    
        if (result.data && result.data.removeUserFromOrg && result.data.removeUserFromOrg.ok) {
          return { success: true, error: null };
        }
    
        return { success: false, error: result.errors[0].message };
      }  

    async updateUserPermissions(userId: string, orgId: string, permissions: { [key: string]: boolean | undefined }) {
    await this.ensureDataSourceInitialized();
    
    const payload = {
        userId: userId,
        orgId: orgId,
        permissions: permissions,
    };
    
    const result: any = await this.datasource.postResource('updateUserPermissions', payload);
    if (result.data && result.data.modifyUserPermissions && result.data.modifyUserPermissions.ok) {
        return { success: true, error: null };
    }
    
    return { success: false, error: result.errors[0].message };
    }
      
    async createUser(userInfo: { username: string; password: string; email: string }, skipConfirmation: boolean) {
        await this.ensureDataSourceInitialized();
        
        const payload = {
            userInfo: userInfo,
            skipConfirmation: skipConfirmation,
        };
        
        const result: any = await this.datasource.postResource('createUser', payload);
        if (result.data && result.data.createUser && result.data.createUser.ok) {
            return { success: true, error: null };
        }
        
        return { success: false, error: result.data.createUser.message };
    }

    async updateAsset(assetId: string, asset: AssetUpdateInput){
        await this.ensureDataSourceInitialized();
        const result : UpdateAssetResponse = await this.datasource.postResource('update-asset', { assetId: assetId, asset: asset });
        const updateAssetData = result.data.updateAsset;
        if (updateAssetData.ok) {
            return {success:true, error: null};
        }
        return {success:false, error: updateAssetData.errors};
    }

    async createPart(payload: { asset_id: string; name: string; description?: string; number?: string; manufactured_date?: string }) {
        await this.ensureDataSourceInitialized();
    
        const result: CreateAssetPartResponse = await this.datasource.postResource('create-asset-part', { part: payload });
        const createPartData = result.data.createAssetPart;
    
        if (createPartData.ok) {
            return { success: true, error: null };
        }
        return { success: false, error: createPartData.errors };
    }
    

    async deletePart(assetId: string, partId: string){
        await this.ensureDataSourceInitialized();
        const result : DeleteAssetPartResponse = await this.datasource.postResource('delete-asset-part', { assetId: assetId, partId: partId });
        if (result.data.deleteAssetPart.ok) {
            return {success:true, error: null};
        }
        return {success:false, error: result.data.deleteAssetPart.errors};

    }

    async getDevices(){
        await this.ensureDataSourceInitialized();
        const result : GetDeviceListResponse = await this.datasource.postResource('fetch-devices');
        const flexcases = result.data.assets.items;
        return flexcases;        
    }

    async paymentFlexcase(deviceId: string[]){
        await this.ensureDataSourceInitialized();
        const result : PaymentResponse = await this.datasource.postResource('FlexPayment', { devices: deviceId });
        if (result.token) {
            return {success:true, token: result.token};
        }
        return {success:false, error: "Payment failed"};
    }

    async getAssetSettings(){
        await this.ensureDataSourceInitialized();
        const result = await this.datasource.postResource('get-asset-settings');
        const settings : AssetListElementSettings[] = result.data.assets.items;
        return settings;
    }

    async updateAssetSettings(assetId: string, settings: any) {
        try {
            await this.ensureDataSourceInitialized();
            const result: AssetSettingsResponse = await this.datasource.postResource('update-asset-settings', { assetId: assetId, settings: settings });
            return {success:true, error: null};
        } catch (error) {
            return {success:false, error: "Error updating asset settings: " + error};
        }
    }
       
}
export default DataService;
