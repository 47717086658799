import React from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import AxleDetails from '../Axle/AxleDetailsForm';
import AssetDetails from './AssetDetails';
import { Button, FormControl, TextField } from '@mui/material';
import { AssetInput, AxleCount, WheelConfiguration } from 'types';
import BatteryDetails from 'components/Battery/BatteryDetails';
import OdometryInput from 'components/Odometry/OdometryForm';
import DataService from 'services/dataService';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';


const CreateAsset: React.FC = () => {
  const [flexcase, setFlexcase] = React.useState('');
  const [asset, setAsset] = React.useState('');
  const [axleCount, setAxleCount] = React.useState(AxleCount.Default);
  const [wheelConfiguration, setWheelConfiguration] = React.useState(WheelConfiguration.Default);
  const [VIN , setVIN] = React.useState('');
  const [model, setModel] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [color, setColor] = React.useState('');
  const [year, setYear] = React.useState(0);
  const [cycleNumber, setCycleNumber] = React.useState(0);
  const [odometry, setOdometry] = React.useState(0);
  const [serviceDate, setServiceDate] = React.useState('');
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [severity, setSeverity] = React.useState<'success' | 'error' | null>(null);
 

  const handleChangeAxle = (event: SelectChangeEvent) => {
    setAxleCount(event.target.value as AxleCount);
  };

  const handleChangeWheel = (event: SelectChangeEvent) => {
    setWheelConfiguration(event.target.value as WheelConfiguration);
  };

  const handleCreate = async () => {
    const createPayload:  AssetInput = {
      name: asset,
      VIN: VIN,
      year: year,
      model: model,
      category: category,
      color: color,
      flexcase: flexcase,
      battery: {
        cycleNumber: cycleNumber,
      },
      axles: {
        axleCount: axleCount,
        wheelConfiguration: wheelConfiguration,
      },
      odometer: {
        mandatoryServiceDistance: odometry,
        serviceDate: serviceDate,
       }
      };
      const dataService = new DataService();
      const result = await dataService.createAsset(createPayload);
      if (result.success) {
        setAlertMessage('Asset created successfully!');
        setSeverity('success');
      } else {
        setAlertMessage(`Error creating asset: ${result.error?.join(', ')}`);
        setSeverity('error');
      }      
    };

  return (
    <FormControl fullWidth>
      <br />
      <h1>Create Asset</h1>
      <br />
    <TextField
    label="Asset Name"
    variant="outlined"
    value={asset}
    onChange={(event) => setAsset(event.target.value)}
    />
    <AssetDetails
    VIN={VIN}
    model={model}
    category={category}
    color={color}
    flexcase={flexcase}
    year={year}
    required = {true}
    handleChange={(field, value) => {
        if (field === 'VIN') setVIN(value);
        else if (field === 'model') setModel(value);
        else if (field === 'category') setCategory(value);
        else if (field === 'color') setColor(value);
        else if (field === 'flexcase') setFlexcase(value);
        else if (field === 'year') setYear(Number(value));
    }}
    />
    <AxleDetails 
    axleCount={axleCount} 
    wheelConfiguration={wheelConfiguration} 
    required = {true}
    handleAxleChange={handleChangeAxle} 
    handleWheelChange={handleChangeWheel} 
    />

    <BatteryDetails
      cycleNumber={cycleNumber}
      required = {true}
      handleChange={(e) => setCycleNumber(Number(e.target.value))}
    />

    <OdometryInput 
    serviceDistance={odometry}
    serviceDate = {serviceDate}
    handleChange={(e) => setOdometry(Number(e.target.value))}
    handleDateChange={(e) => setServiceDate(e.target.value)}
    />

    <Button variant="contained" color="primary" onClick={handleCreate}>
    Create Asset
    </Button>
    {severity && alertMessage && (
      <Alert
        icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
        severity={severity}
      >
        {alertMessage}
      </Alert>
    )}
    </FormControl>
  );
};

export default CreateAsset;
