import React from 'react';
import { TextField } from '@mui/material';

interface AssetDetailsProps {
  VIN: string;
  model: string;
  category: string;
  color: string;
  flexcase: string;
  year: number;
  handleChange: (field: string, value: string) => void;
  required?: boolean;
  disabled?: boolean;
}

const AssetDetails: React.FC<AssetDetailsProps> = ({ VIN, model, category, color, flexcase, year, handleChange,required,disabled }) => {
  return (
    <div>
      <h2>Asset Details</h2>
      <TextField
        label="VIN"
        value={VIN}
        required = {required}
        onChange={(e) => handleChange('VIN', e.target.value)}
        style={{ marginBottom: '10px' }}
        disabled={disabled}
      />
      <TextField
        label="Year"
        value={year}
        required = {required}
        type = "number"
        onChange={(e) => handleChange('year', e.target.value)}
        style={{ marginBottom: '10px' }}
        disabled={disabled}
      />
      <TextField
        label="Model"
        required = {required}
        value={model}
        onChange={(e) => handleChange('model', e.target.value)}
        style={{ marginBottom: '10px' }}
        disabled={disabled}
      />
      <TextField
        label="Category"
        required = {required}
        value={category}
        onChange={(e) => handleChange('category', e.target.value)}
        style={{ marginBottom: '10px' }}
        disabled={disabled}
      />
      <TextField
        label="Color"
        required = {required}
        value={color}
        onChange={(e) => handleChange('color', e.target.value)}
        style={{ marginBottom: '10px' }}
        disabled={disabled}
      />
      <TextField
        label="Flexcase"
        required = {required}
        value={flexcase}
        onChange={(e) => handleChange('flexcase', e.target.value)}
        style={{ marginBottom: '10px' }}
        disabled={disabled}
      />
    </div>
  );
};

export default AssetDetails;
