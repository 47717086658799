import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  FormGroup,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import DataService from 'services/dataService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { OrganizationInfo, User } from 'types';





const AddUser: React.FC = () => {
  const [userId, setUserId] = useState<string>('');
  const [orgId, setOrgId] = useState<string>('');
  const [queryRgId, setQueryRgId] = useState<string>('');
  const [subOrgs, setSubOrgs] = useState<OrganizationInfo[]>([]); 
  const [selectedOrgUsers, setSelectedOrgUsers] = useState<User[]>([]); 
  const [readPermission, setReadPermission] = useState<boolean>(false);
  const [writePermission, setWritePermission] = useState<boolean>(false);
  const [adminPermission, setAdminPermission] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<'success' | 'error' | null>(null);

  const handleResetPermissions = () => {
    setReadPermission(false);
    setWritePermission(false);
    setAdminPermission(false);
  };

  const filterPermissions = () => {
    return {
      read: readPermission,
      write: writePermission,
      admin: adminPermission,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datasource = new DataService();
        const orgInfo =  await datasource.get_orgs_users_info();
        setSubOrgs(orgInfo.organizations);
        
      } catch (error) {
        setAlertMessage('Error fetching organization info: ' + (error instanceof Error ? error.message : 'Unknown error occurred'));
        setSeverity('error');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const selectedOrg = subOrgs.find((org) => org.id === orgId);
    setSelectedOrgUsers(selectedOrg ? selectedOrg.users : []); // Updated to use user objects
  }, [orgId, subOrgs]);

  const handleCreate = async () => {
    const permissions = filterPermissions(); 

    try {
      const dataService = new DataService();
      const result = await dataService.addUserToOrg(userId, orgId, permissions);

      if (result.success) {
        setAlertMessage('User added to organization successfully!');
        setSeverity('success');
      } else {
        setAlertMessage(`Error adding user: ${result.error}`);
        setSeverity('error');
      }
    } catch (error) {
      setAlertMessage('Error adding user: ' + (error instanceof Error ? error.message : 'Unknown error'));
      setSeverity('error');
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleCreate();
  };

  return (
    <Box mb={2}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="org-select-label">Organization</InputLabel>
              <Select
                labelId="org-select-label"
                value={orgId}
                onChange={(e) => setOrgId(e.target.value)}
              >
                {subOrgs.map((subOrg) => (
                  <MenuItem key={subOrg.id} value={subOrg.id}>
                    {subOrg.name || 'Unnamed Organization'} 
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="user-select-label">User</InputLabel>
              <Select
                labelId="user-select-label"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              >
                {selectedOrgUsers.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.username} {/* Display the username, using user ID as value */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="queryRgId-select-label">Target Organization ID</InputLabel>
              <Select
                labelId="queryRgId-select-label"
                value={queryRgId}
                onChange={(e) => setQueryRgId(e.target.value)}
              >
                {subOrgs.map((subOrg) => (
                  <MenuItem key={subOrg.id} value={subOrg.id}>
                    {subOrg.name || 'Unnamed Organization'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Permissions:</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={readPermission}
                    onChange={() => setReadPermission((prev) => !prev)} // Toggle between true and false
                  />
                }
                label="Read"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={writePermission}
                    onChange={() => setWritePermission((prev) => !prev)} // Toggle between true and false
                  />
                }
                label="Write"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminPermission}
                    onChange={() => setAdminPermission((prev) => !prev)} // Toggle between true and false
                  />
                }
                label="Admin"
              />
            </FormGroup>
            <Button variant="outlined" onClick={handleResetPermissions} sx={{ mt: 1 }}>
              Reset Permissions
            </Button>
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Submit
        </Button>
      </form>

      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
};

export default AddUser;
