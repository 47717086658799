import React, { useEffect } from 'react';
import { Button, FormControl, FormControlLabel, FormGroup, Switch, Typography, Alert } from '@mui/material';
import DataService from 'services/dataService';
import { Flexcase } from '../../types';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const AssetList: React.FC = () => {
  const [deviceList, setDeviceList] = React.useState<Flexcase[]>([]);
  const [subscribedDevices, setSubscribedDevices] = React.useState<string[]>([]); // State for subscribed device IDs
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [severity, setSeverity] = React.useState<'success' | 'error' | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const dataService = new DataService();
      const devices = await dataService.getDevices();
      setDeviceList(devices);
    };
    fetchData();
  }, []);

  const handleSwitchChange = (deviceId: string, isChecked: boolean) => {    
    if (isChecked) {
      setSubscribedDevices((prev) => [...prev, deviceId]);
    } else {
      setSubscribedDevices((prev) => prev.filter(id => id !== deviceId));
    }
    setDeviceList((prevList) => 
      prevList.map(device =>
        device.flexcase.deviceId === deviceId
          ? { ...device, flexcase: { ...device.flexcase, subscribed: isChecked } }
          : device
      )
    );
    
    setAlertMessage(`Current subscribed devices: ${subscribedDevices.join(', ')}`);
    setSeverity('success');
  };

  const handlePaymentRedirect = (token: string) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://test.authorize.net/payment/payment';
    form.id = 'formAuthorizeNetTestPage';
    form.target = '_blank'; // Open in a new tab
  
    const inputToken = document.createElement('input');
    inputToken.type = 'hidden';
    inputToken.name = 'token';
    inputToken.value = token; 
  
    form.appendChild(inputToken);
  
    document.body.appendChild(form);
    form.submit();
  };

  async function handleSubscribePayments() {
    const dataService = new DataService();
    const result = await dataService.paymentFlexcase(subscribedDevices);
    if (result.success) {
      setAlertMessage('Successfully subscribed to payments');
      setSeverity('success');
      handlePaymentRedirect(result.token? result.token : '');
    }
    else {
      setAlertMessage('Failed to subscribe to payments');
      setSeverity('error');
    }
  }

  return (
    <FormControl component="fieldset">
      <Typography variant="h4" gutterBottom>
        Asset List
      </Typography>
      <FormGroup>
        {deviceList.map((device) => (
          <FormControlLabel
            key={device.flexcase.deviceId}
            control={
              <Switch
                checked={device.flexcase.subscribed}
                onChange={(e) => handleSwitchChange(device.flexcase.deviceId, e.target.checked)} // Pass checked state
                color="primary"
              />
            }
            label={device.flexcase.deviceId}
          />
        ))}
      </FormGroup>
      <Button onClick={() => handleSubscribePayments()}>
        Show Subscribed Devices
      </Button>
      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </FormControl>
  );
};

export default AssetList;
