import React, {useEffect} from 'react';
import { TextField } from '@mui/material';

interface OdometryInputProps {
    serviceDistance: number;
    serviceDate: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
  }
  
  const OdometryInput: React.FC<OdometryInputProps> = ({ serviceDistance, serviceDate, handleChange, handleDateChange,disabled }) => {
    const [hasDistanceChanged, setHasDistanceChanged] = React.useState(false);
    const [hasDateChanged, setHasDateChanged] = React.useState(false);
    const [bothChanged, setBothChanged] = React.useState(false);


    useEffect(() => {
      if (serviceDistance !== 0 && serviceDate === '') {
        setHasDistanceChanged(true);
        setBothChanged(false);
      } else if (serviceDistance !== 0 && serviceDate !== '') {
        setHasDistanceChanged(false);
        setHasDateChanged(false);
        setBothChanged(true);
      }
      else if (serviceDate !== '' && serviceDistance === 0) {
        setHasDateChanged(true);
        setBothChanged(false);
      } else if (serviceDate !== '' && serviceDistance !== 0) {
        setHasDateChanged(false);
        setHasDistanceChanged(false);
        setBothChanged(true); 
      }
    }, [serviceDistance, serviceDate]);

    return (
      <div>
        <h3>Odometry</h3>
        <TextField
          value={serviceDistance}
          onChange={handleChange}
          type="number"
          fullWidth
          error={hasDateChanged && !bothChanged}
          style={{ marginBottom: '10px'}}
          label = {hasDateChanged && !bothChanged ? "Please enter a service distance" : "Mandatory Service Distance"}
          disabled={disabled}
        />
      <TextField
        value={serviceDate}
        onChange={handleDateChange}
        type="date"
        fullWidth
        error={hasDistanceChanged && !bothChanged}
        label={hasDistanceChanged && !bothChanged ? "Please enter a service date" : "Service Date"}
        InputLabelProps={{
          shrink: true,  
        }}
        style={{ marginBottom: '10px' }}
        disabled={disabled}
      />

      </div>
    );
  };
  
  export default OdometryInput;
  