import React, { useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AssetDropDown from '../Assets/AssetsDropdown';
import { Alert, Button, FormControl, MenuItem } from '@mui/material';
import DataService from 'services/dataService';
import { AssetListElement, Part } from '../../types';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const DeleteAssetPart: React.FC = () => {
  const [assetName, setAssetName] = React.useState('');
  const [assetId, setAssetId] = React.useState('');
  const [assetsList, setAssetsList] = React.useState<AssetListElement[]>([]);
  const [selectedAsset, setSelectedAsset] = React.useState<AssetListElement | null>(null);
  const [selectedPart, setSelectedPart] = React.useState<Part | null>(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [severity, setSeverity] = React.useState<'success' | 'error' | null>(null);

  const handleChangeAssetName = (event: SelectChangeEvent) => {
    const selectedName = event.target.value as string;
    setAssetName(selectedName);

    const foundAsset = assetsList.find((asset) => asset.name === selectedName);
    if (foundAsset) {
      setSelectedAsset(foundAsset);
      setAssetId(foundAsset.ID);
      setSelectedPart(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datasource = new DataService();
        const assets = await datasource.getAssets();
        setAssetsList(assets);
      } catch (error) {
        setAlertMessage('Error fetching assets: ' + error);
        setSeverity('error');
      }
    };

    fetchData();
  }, []);

  const handleDeleteAssetPart = async () => {
    if (!assetId || !selectedPart?.id) {
      setAlertMessage('Asset ID or Part ID is not set. Cannot delete.');
      setSeverity('error');
      return;
    }
    const dataService = new DataService();
    const result = await dataService.deletePart(assetId, selectedPart.id);
    if (result.success) {
      if (selectedAsset) {
        const updatedParts = selectedAsset.parts.filter(part => part.id !== selectedPart.id);
        setSelectedAsset({ ...selectedAsset, parts: updatedParts });
        setSelectedPart(null);
      }
      setAlertMessage('Asset part deleted successfully!');
      setSeverity('success');
      
    } else {
      setAlertMessage(`Error deleting asset part: ${result.error?.join(', ')}`);
      setSeverity('error');
    }
  };

  const handleChangePartName = (event: SelectChangeEvent) => {
    const selectedPartName = event.target.value as string;
    const part = selectedAsset?.parts.find((part) => part.name === selectedPartName);
    if (part) {
      setSelectedPart({ name: part.name, id: part.id });
    }
  };

  // Determine if the form should be disabled
  const isFormDisabled = !selectedAsset || selectedAsset.parts.length === 0;

  return (
    <FormControl fullWidth>
      <br />
      <h1>Delete Asset Part</h1>
      <br />
      {assetsList.length === 0 ? (
        <Alert severity="warning">No assets available</Alert>
      ) : (
        <AssetDropDown value={assetName} options={assetsList} handleChange={handleChangeAssetName} />
      )}
      {selectedAsset && selectedAsset.parts.length > 0 && (
        <>
          <Select
            labelId="part-select-label"
            id="part-select"
            value={selectedPart?.name || ''}
            onChange={handleChangePartName}
            fullWidth
            disabled={isFormDisabled} // Disable select if no parts are available
          >
            {selectedAsset.parts.map((part, index) => (
              <MenuItem key={index} value={part.name}>
                {part.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleDeleteAssetPart} 
        disabled={isFormDisabled} // Disable button if no asset or parts
      >
        Delete Asset Part
      </Button>

      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </FormControl>
  );
};

export default DeleteAssetPart;
