import React, { useState } from 'react';
import { PanelProps } from '@grafana/data';
import { Tabs, Tab, Box } from '@mui/material';
import Assets from './Assets/Assets';
import Users from './Users/Users';
import Payments from './Payments/Payments';
import Parts from './Parts/Parts';
import { SimpleOptions } from '../types';

interface Props extends PanelProps<SimpleOptions> {
  height: number;
  width: number;
}

const SimplePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {
  const [activeTab, setActiveTab] = useState(0); 

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <Assets />;
      case 1:
        return <Parts />;
      case 2:
        return <Users />;
      case 3:
        return <Payments />;
      default:
        return <Assets />;
    }
  };

  if (data.series.length === 0) {
    return (
      <div>
        <h3>No data available</h3>
      </div>
    );
  }

  return (
    <Box sx={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden' }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="asset tabs"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="Assets" />
        <Tab label="Parts" />
        <Tab label="Users" />
        <Tab label="Payments" />
      </Tabs>

      <Box sx={{ padding: 2, maxHeight: `calc(${height}px - 60px)`, overflowY: 'auto' }}>
        {renderTabContent()}
      </Box>
    </Box>
  );
};

export default SimplePanel;
