import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

import AddUser from './AddUser';
import UpdateUserPermissions from './UpdateUserPermissions';
import CreateUser from './CreateUser'; 
import RemoveUser from './RemoveUser'; 
import UpdateUser from './UpdateUser'; 

const User: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("addUser");


  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs 
        value={selectedTab} 
        onChange={handleTabChange} 
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Add User" value="addUser" />
        <Tab label="Update Permissions" value="updatePermissions" />
        <Tab label="Create User" value="createUser" />
        <Tab label="Remove User" value="removeUser" />
        <Tab label="Update User" value="updateUser" />
      </Tabs>

      <Box sx={{ padding: 2 }}>
        {selectedTab === "addUser" && <AddUser />}
        {selectedTab === "updatePermissions" && <UpdateUserPermissions />}
        {selectedTab === "createUser" && <CreateUser />}
        {selectedTab === "removeUser" && <RemoveUser />}
        {selectedTab === "updateUser" && <UpdateUser />}
      </Box>
    </Box>
  );
};

export default User;
