import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
} from '@mui/material';
import DataService from 'services/dataService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { OrganizationInfo, User } from 'types';



const RemoveUser: React.FC = () => {
  const [userId, setUserId] = useState<string>('');
  const [orgId, setOrgId] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]); // Users of the selected organization
  const [subOrgs, setSubOrgs] = useState<OrganizationInfo[]>([]); // List of organizations
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataService = new DataService();
        const orgInfo =  await dataService.get_orgs_users_info();
        setSubOrgs(orgInfo.organizations);
      } catch (error) {
        setAlertMessage('Error fetching organization info: ' + (error instanceof Error ? error.message : 'Unknown error occurred'));
        setSeverity('error');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const selectedOrg = subOrgs.find((org) => org.id === orgId);
    setUsers(selectedOrg ? selectedOrg.users : []); // Set users based on the selected organization
  }, [orgId, subOrgs]);

  const handleRemove = async () => {
    try {
      const dataService = new DataService();
      const result = await dataService.removeUser(userId, orgId);

      if (result.success) {
        setAlertMessage('User removed from organization successfully!');
        setSeverity('success');
      } else {
        setAlertMessage(`Error removing user: ${result.error}`);
        setSeverity('error');
      }
    } catch (error) {
      setAlertMessage('Error removing user: ' + (error instanceof Error ? error.message : 'Unknown error'));
      setSeverity('error');
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleRemove();
  };

  return (
    <Box mb={2}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="org-select-label">Organization</InputLabel>
              <Select
                labelId="org-select-label"
                value={orgId}
                onChange={(e) => setOrgId(e.target.value)}
              >
                {subOrgs.map((subOrg) => (
                  <MenuItem key={subOrg.id} value={subOrg.id}>
                    {subOrg.name || 'Unnamed Organization'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="user-select-label">User</InputLabel>
              <Select
                labelId="user-select-label"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Remove User
        </Button>
      </form>

      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
};

export default RemoveUser;
