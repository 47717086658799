import React from 'react';
import AssetList from './DeviceList';

const Payments: React.FC = () => {


  return (
    <AssetList />
  );
};

export default Payments;
