import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { AxleCount, WheelConfiguration } from '../../types';

interface AxleDetailsProps {
  axleCount: AxleCount;
  wheelConfiguration: WheelConfiguration;
  required?: boolean;
  handleAxleChange: (event: SelectChangeEvent) => void;
  handleWheelChange: (event: SelectChangeEvent) => void;
  disabled?: boolean; 
}

const axleToWheelOptions: Record<AxleCount, { label: string; value: WheelConfiguration }[]> = {
  [AxleCount.ONE]: [
    { label: "Single Wheel", value: WheelConfiguration.ONE_TWO },
    { label: "Dual Wheel", value: WheelConfiguration.ONE_FOUR },
  ],
  [AxleCount.TWO]: [
    { label: "Single Wheel", value: WheelConfiguration.TWO_TWO_BY_TWO },
    { label: "Dual Wheel", value: WheelConfiguration.TWO_FOUR_BY_FOUR },
  ],
  [AxleCount.THREE]: [
    { label: "Single Wheel", value: WheelConfiguration.THREE_TWO_TWO_TWO },
    { label: "Dual Wheel", value: WheelConfiguration.THREE_FOUR_FOUR_FOUR },
  ],
  [AxleCount.Default]: [{ label: "Default", value: WheelConfiguration.Default }],
};

const AxleDetails: React.FC<AxleDetailsProps> = ({
  axleCount,
  wheelConfiguration,
  required,
  handleAxleChange,
  handleWheelChange,
  disabled,
}) => {
  const [hasAxleChanged, setHasAxleChanged] = React.useState(false);
  const [hasWheelChanged, setHasWheelChanged] = React.useState(false);
  const [hasBothChanged, setHasBothChanged] = React.useState(false);

  useEffect(() => {
    if (axleCount !== AxleCount.Default && wheelConfiguration === WheelConfiguration.Default) {
      setHasAxleChanged(true);
      setHasBothChanged(false);
    } else if (axleCount !== AxleCount.Default && wheelConfiguration !== WheelConfiguration.Default) {
      setHasAxleChanged(false);
      setHasWheelChanged(false);
      setHasBothChanged(true);
    } else if (axleCount !== AxleCount.Default && wheelConfiguration === WheelConfiguration.Default) {
      setHasWheelChanged(true);
      setHasBothChanged(false);
    }
  }, [axleCount, wheelConfiguration]);

  const availableWheelOptions = axleToWheelOptions[axleCount] || [{ label: "Default", value: WheelConfiguration.Default }];

  return (
    <div>
      <h3>Axle and Wheel Configuration</h3>
      
      {/* Axle Count Dropdown */}
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <InputLabel id="axle-count-label">Axle Count</InputLabel>
        <Select
          required={required}
          labelId="axle-count-label"
          label={hasWheelChanged && !hasBothChanged ? "Please select an axle count" : "Axle Count"}
          value={axleCount}
          onChange={handleAxleChange}
          error={hasWheelChanged && !hasBothChanged}
          disabled={disabled}
        >
          <MenuItem value={AxleCount.ONE}>Single Axle</MenuItem>
          <MenuItem value={AxleCount.TWO}>Tandem Axle</MenuItem>
          <MenuItem value={AxleCount.THREE}>Triple Axle</MenuItem>
        </Select>
      </FormControl>

      {/* Wheel Type Dropdown */}
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <InputLabel id="wheel-type-label">Wheel Type</InputLabel>
        <Select
          labelId="wheel-type-label"
          value={wheelConfiguration}
          required={required}
          onChange={handleWheelChange}
          disabled={axleCount === AxleCount.Default}  // Disable until axle is selected
          error={hasAxleChanged && !hasBothChanged}
          label={hasAxleChanged && !hasBothChanged ? "Please select a wheel type" : "Wheel Type"}
        >
          {availableWheelOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AxleDetails;
