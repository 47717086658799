import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Alert,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DataService from 'services/dataService';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const UpdateUser: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [verifyEmail, setVerifyEmail] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<'success' | 'error' | null>(null);

  const handleUpdate = async () => {
    try {
      const dataService = new DataService();

      // Construct userInfo object only with non-empty fields
      const userInfo: { [key: string]: any } = {};

      if (firstName) userInfo.firstName = firstName;
      if (lastName) userInfo.lastName = lastName;
      if (email) userInfo.email = email;
      if (phoneNumber) userInfo.phoneNumber = phoneNumber;
      if (verifyEmail) userInfo.verifyEmail = verifyEmail;

      console.log(userInfo);
      const result = await dataService.updateUser(userInfo);

      if (result.success) {
        setAlertMessage('User updated successfully!');
        setSeverity('success');
      } else {
        setAlertMessage(`Error updating user: ${result.error}`);
        setSeverity('error');
      }
    } catch (error) {
      setAlertMessage('Error updating user: ' + (error instanceof Error ? error.message : 'Unknown error'));
      setSeverity('error');
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleUpdate();
  };

  return (
    <Box mb={2}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={verifyEmail}
                  onChange={(e) => setVerifyEmail(e.target.checked)}
                />
              }
              label="Verify Email"
            />
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Update
        </Button>
      </form>

      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
};

export default UpdateUser;
