import React, { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box, Slider, Typography, Button, Alert, FormControl } from '@mui/material';
import AssetDropDown from './AssetsDropdown';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import DataService from 'services/dataService';
import { AssetListElementSettings, AssetSettings } from '../../types';

const UpdateAsset: React.FC = () => {
  const [assetName, setAssetName] = useState('');
  const [assetID, setAssetID] = useState('');
  const [assetsList, setAssetsList] = useState<AssetListElementSettings[]>([]);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<'success' | 'error' | null>(null);
  const [settings, setSettings] = useState<AssetSettings>();
  const [anchorDistance, setAnchorDistance] = useState(0);
  const [ambientTemp, setAmbientTemp] = useState(0);
  const [criticalTemp, setCriticalTemp] = useState(0);
  const [nominalPressure, setNominalPressure] = useState(0);
  const [highPressure, setHighPressure] = useState(0);
  const [lowPressure, setLowPressure] = useState(0);

  // Load asset settings
  useEffect(() => {
    const fetchData = async () => {
      try {
        const datasource = new DataService();
        const assets: AssetListElementSettings[] = await datasource.getAssetSettings();
        setAssetsList(assets);
      } catch (error) {
        setAlertMessage('Error fetching assets: ' + error);
        setSeverity('error');
      }
    };

    fetchData();
  }, []);

  // Update related values when ambient temperature changes
  useEffect(() => {
    if (settings) {
      setCriticalTemp(ambientTemp + settings.tireTemperatureHighDelta);
    }
  }, [ambientTemp, settings]);

  // Update high and low pressure when nominal pressure changes
  useEffect(() => {
    if (settings) {
      setHighPressure(nominalPressure + settings.tirePressureHighDelta);
      setLowPressure(nominalPressure - settings.tirePressureLowDelta);
    }
  }, [nominalPressure, settings]);

  // Function to handle asset selection and update the default settings
  const handleChangeAssetName = (event: SelectChangeEvent) => {
    const selectedName = event.target.value as string;
    setAssetName(selectedName);

    const selectedAsset = assetsList.find((asset) => asset.name === selectedName);
    if (selectedAsset) {
      const settings = selectedAsset.settings;
      setAnchorDistance(settings.anchorTolerance);
      setAmbientTemp(settings.tireTemperatureAmbient);
      setNominalPressure(settings.tirePressureSet);
      setAssetID(selectedAsset.id);
      setSettings(selectedAsset.settings);
    }
  };

  const handleUpdateSettings = async () => {
    const updatedSettings: any = {};
    const settings = assetsList.find((asset) => asset.name === assetName)?.settings;

    if (anchorDistance !== settings?.anchorTolerance) {
      updatedSettings.anchorTolerance = anchorDistance;
    }
    if (ambientTemp !== settings?.tireTemperatureAmbient) {
      updatedSettings.tireTemperatureAmbient = ambientTemp;
    }

    if (criticalTemp !== ((settings?.tireTemperatureHighDelta ?? 0) + ambientTemp)) {
      updatedSettings.tireTemperatureAmbient = criticalTemp - (settings?.tireTemperatureHighDelta ?? 0);
    }

    if (nominalPressure !== settings?.tirePressureSet) {
      updatedSettings.tirePressureSet = nominalPressure;
    }

    if (highPressure > ((settings?.tirePressureHighDelta ?? 0) + nominalPressure)) {
      updatedSettings.tirePressureMaxValue = highPressure;
    }

    if (lowPressure < (nominalPressure - (settings?.tirePressureLowDelta ?? 0))) {
      updatedSettings.tirePressureMinValue = lowPressure + (settings?.tirePressureLowDelta ?? 0);
    }

    const dataService = new DataService();
    const response = await dataService.updateAssetSettings(assetID, updatedSettings);
    if (response.success) {
      setAlertMessage('Asset settings updated successfully!');
      setSeverity('success');
    } else {
      setAlertMessage('Error updating asset settings: ' + response.error);
      setSeverity('error');
    }
  };

  return (
    <FormControl fullWidth>
      <Typography variant="h4">Update Asset Settings</Typography>
      <Box mt={2}>
      {assetsList.length === 0 ? (
        <Alert severity="warning">No assets available</Alert>
      ) : (
        <AssetDropDown value={assetName} optionsSettings={assetsList} handleChange={handleChangeAssetName} />
      )}
      </Box>
      {assetName && (
        <>
          <Typography mt={4} variant="h6">Anchor</Typography>
          <Typography variant="body2">Anchor ({anchorDistance} m)</Typography>
          <Slider
            value={anchorDistance}
            min={0}
            max={500}
            onChange={(e, newValue) => setAnchorDistance(newValue as number)}
          />

          <Typography mt={4} variant="h6">Tire Temperature</Typography>
          <Typography variant="body2">Ambient Temperature ({ambientTemp} °C)</Typography>
          <Slider
            value={ambientTemp}
            min={-50}
            max={50}
            onChange={(e, newValue) => setAmbientTemp(newValue as number)}
          />
          <Typography variant="body2">Critical Temperature ({criticalTemp} °C)</Typography>
          <Slider
            value={criticalTemp}
            min={50}
            max={150}
            onChange={(e, newValue) => setCriticalTemp(newValue as number)}
          />

          {/* Tire Pressure Sliders */}
          <Typography mt={4} variant="h6">Tire Pressure</Typography>
          <Typography variant="body2">Nominal Pressure ({nominalPressure} kPa)</Typography>
          <Slider
            value={nominalPressure}
            min={0}
            max={1000}
            onChange={(e, newValue) => setNominalPressure(newValue as number)}
          />
          <Typography variant="body2">Critical High Pressure ({highPressure} kPa)</Typography>
          <Slider
            value={highPressure}
            min={500}
            max={1000}
            onChange={(e, newValue) => setHighPressure(newValue as number)}
          />
          <Typography variant="body2">Critical Low Pressure ({lowPressure} kPa)</Typography>
          <Slider
            value={lowPressure}
            min={settings ? Math.floor(settings.tirePressureSet * 0.9) : 0}
            max={settings ? settings.tirePressureSet : 100}
            onChange={(e, newValue) => setLowPressure(newValue as number)}
            valueLabelDisplay="auto"
          />
        </>
      )}

      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleUpdateSettings}>
          Submit
        </Button>
      </Box>

      {severity && alertMessage && (
        <Alert
          icon={severity === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorIcon fontSize="inherit" />}
          severity={severity}
        >
          {alertMessage}
        </Alert>
      )}
    </FormControl>
  );
};

export default UpdateAsset;

